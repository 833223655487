.user-modal-container{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-modal-box{
    width: 300px;
    min-height: 50px;
    background: white;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.user-modal-box form{
    margin-top: 40px;
    width: 280px;
    margin-bottom: 20px;
}
.user-in-box{
    height: 40px;
    background: #f2f2f2;
    border: 1px solid #DDDDDD;
    display: flex;
    align-items: center;
    margin-top: 10px;
    position: relative;
}

.forget-pass{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: end;
}
.forget-pass-title{
    margin-bottom: 20px;
}
.forget-pass span{
    cursor: pointer;
    color: rgba(72, 201, 176, 1);
    transition: .3s;
    font-size: 16px;
}
.forget-pass span:hover{
    color: rgb(48, 156, 135);
}

.user-in-box input{
    flex-grow: 1;
    height: 70%;
    outline: none;
    background: #f2f2f2;
    border: none;
}
.user-icon-box{
    height: 100%;
    width: 40px;
    background: #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, .8);
}

.eye-btn{
    position: absolute;
    right: 2px;
    background: none;
    border: none;
    cursor: pointer;
}

.cancel-usd-modal{
    position: absolute;
    top: 10px;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
    text-align: end;
}

.sign-btn{
    height: 40px;
    width: 280px;
    background: rgba(72, 201, 176, 1);
    color: #f2f2f2;
    font-size: 16px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(72, 201, 176, 1);
    cursor: pointer;
    transition: .3s;
}
.sign-btn:hover{
    background: rgb(48, 156, 135);
}

.user-detail-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.user-detail-container img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .2);
}
.user-detail-container p{
    display: flex;
    align-items: center;
    padding:0;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, .8);
}
.user-detail-container p span{
    margin-left: 7px;
}

.phone-not-valid{
    margin-top: 5px;
    color: red;
}

.disable{
    cursor: not-allowed;
    opacity: .2;
}

.code-verification{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.digit-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.digit-container input{
    height: 40px;
    width: 40px;
    border-radius: 3px;
    border: rgb(48, 156, 135);
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.code-verification button{
    height: 45px;
    width: 45px;
    background: rgba(72, 201, 176, 1);
    border: 1px solid rgba(72, 201, 176, 1);
    color: white;
    width: 100%;
    border-radius: 3px;
    transition: .3s;
    margin-top: 15px;
    font-size: 18px;
}
.not-disable{
    cursor: pointer;
}
.disable{
    cursor: not-allowed;
    opacity: .2;
}
.code-verification button:hover{
    background: rgb(48, 156, 135);
}