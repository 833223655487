.error-modal {
    display: none;
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #17202a;
    color: rgb(189, 45, 45);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    height: max-content;
    width: max-content;
  }
  
  .modal-content {
    position: relative;
  }
  
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  