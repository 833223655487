.condition-container{
    flex-grow: 1;
    width: 96%;
    margin-left: 2%;
}

.condition-container h2{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
}

.article{
    width: 100%;
    font-size: x-large;
    font-weight: lighter;
    border-bottom: 1px solid #DDD;
    padding-bottom: 7px;
    margin-top: 15px;
}
.condition-container p{
    font-family: 'Segoe UI';
    font-size: 20px;
    font-weight: lighter;
    line-height: 30px;
    max-width: 96%;
}