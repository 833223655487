.money{
    height: 340px;
    min-width: 240px;
    background: #f2f2f2;
    border-radius: 10px;
    margin-right: 15px;
    margin-top: 1px;
    margin-bottom: 2px;
    margin-left: 2px;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    display: flex;
    flex-direction: column;
}

.money img{
    min-height: 240px;
    min-width: 100%;
    background: black;
    border-radius: 10px 10px 0 0;
}
.money h5{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 3%;
    margin-top: 10px;
    color: #17202a;
}
.money button{
    height: 40px;
    width: 94%;
    background: rgba(72, 201, 176, 1);
    color: #f2f2f2;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    margin-left: 3%;
    font-size: 18px;
    transition: .3s;
}
.money button:hover{
    background: rgb(36, 155, 131);
}