body {
  margin: 0;
  font-family: Palatino, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background: white;
  display: flex;
}
#root{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media screen and (max-width: 400px) {
  body{
    font-size: smaller;
  }
  .cours-title{
    font-size: small;
  }
  .balance-title{
    font-size: small;
  }
}

.cookie-container{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookie-container .cookie{
  background: #F2F2F2;
  padding: 20px;
  max-width: 700px;
}
.cookie-container button{
  color: white;
  border-radius: 3px;
  height: 30px;
  border: none;
  transition: .3s;
  cursor: pointer;
}

.cookie-container p{
  line-height: 30px;
}

.cookie-container button:hover{
  opacity: .8;
}

.cookie .ok{
  background: rgb(63, 175, 63);
}
.cookie .no{
  background: rgb(182, 23, 23);
  margin-left: 20px;
}