.about-container{
    flex-grow: 1;
    display: flex;
    max-width: 96%;
    margin-left: 2%;
    margin-top: 15px;
    flex-direction: column;
}

.about-container span{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.about-title{
    font-size: 45px;
    font-weight: lighter;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-container p{
    font-family: 'Segoe UI';
    font-size: 20px;
    font-weight: lighter;
    line-height: 30px;
    max-width: 96%;
}