.e-box-container{
    width: 100%;
    height: 60px;
    background: #f2f2f2;
    border-radius: 3px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
}
.e-box-container span{
    margin-left: 15px;
}

.status{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-left: 15px;
}
.finish{
    background: red;
}
.not-finish{
    background: green;
}

.not-payed{
    background: rgb(247, 164, 11);
}

.e-box-container button{
    position: absolute;
    right: 15px;
    height: 40px;
    background: rgba(72, 201, 176, 1);
    color: white;
    border: none;
    cursor: pointer;
    transition: .3s;
    border-radius: 3px;
}
.e-box-container button:hover{
    background: rgb(64, 175, 153);
}