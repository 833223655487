.header{
  height: 60px;
  width: 100%;
  border-bottom: 2px solid #48c9b0;
  position: relative;
  display: flex;
  align-items: center;
}
.logo{
  height: 45px;
  width: 45px;
  background: black;
  border-radius: 50%;
  margin-left: 2%;
  cursor: pointer;
}
.header h3{
  color: #17202a;
  margin-left: 10px;
}

.user-box button{
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background: #f2f2f2;
  border: none;
  margin-right: 10px;
  color: #17202a;
  position: relative;
  cursor: pointer;
  transition: .3s;
  box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
}
.user-box button:hover{
  background: #DDD;
}

.user-box button div{
  position: absolute;
  height: 25px;
  width: 25px;
  background: rgb(185, 62, 62);
  color: #f2f2f2;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -2px;
  right: -2px;
  border-radius: 50%;
}

.banner{
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.banner img{
  width: 100%;
}

.footer{
  border-top: 2px solid #48c9b0;
  background: #17202a;
  margin-top: 15px;
}
.copy-right{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f2f2;
  padding-bottom: 5px;
}

.condition-about{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
.condition-about div{
  height: 30px;
  width: 1px;
  background: #f2f2f2;
  margin-right: 5px;
  margin-left: 5px;
}
.condition-about button{
  background: none;
  border: none;
  color: #48c9b0;
  cursor: pointer;
}

.user-box{
  position: absolute;
  right: 2%;
  display: flex;
  align-items: center;
}
.user-box img{
  height: 45px;
  width: 45px;
  background-color: #f2f2f2;
  border-radius: 50%;
  box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
  cursor: pointer;
}

.timeout-container{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}
.timeout-container img{
  width: 100%;
}

@media screen and (max-width: 450px) {
  .user-box img{
    height: 32px;
    width: 32px;
  }
  .user-box button{
    height: 32px;
    width: 32px;
  }
  .user-box button div{
    top: -5px;
    right: -8px;
  }
}