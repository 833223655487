.exchange-container{
    margin-top: 20px;
    width: 96%;
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.defaut-color{
    color: #17202a;
}

.exchange-container h2{
    height: 40px;
    padding: 0;
    margin: 0;
}

.content{
    flex-grow: 1;
    min-height: 100px;
    display: flex;
    flex-direction: column;
}
.none-exchange{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.exchange-modal{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.e-modal-btn-container{
    width: 300px;
    display: flex;
    justify-content: end;
}
.e-modal-btn-container button{
    height: 40px;
    width: 40px;
    border-radius: 3px;
    border: none;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    cursor: pointer;
    transition: .3s;
}
.e-modal-btn-container button:hover{
    background: #f0f0f0;
}
.e-modal-content{
    background: white;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    width: 300px;
    min-height: 100px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}
.m-content{
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.m-content span{
    margin-right: 10px;
}

.finish-text{
    color: red;
}

.not-finish-text{
    color: green;
}

.not-payed-container{
    width: 90%;
}

.done-btn{
    width: 100%;
    background: rgb(55, 163, 142);
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    transition: .3s;
    font-weight: bold;
}
.done-btn:hover{
    background: rgb(46, 141, 122);
}

.warning{
    color: rgb(241, 167, 9);
}