.cours{
    margin-top: 15px;
}
.cours-title{
    color: #17202a;
    display: flex;
    align-items: center;
}
.cours-title span{
    margin-left: 7px;
    font-size: 20px;
}

.cours-container{
    width: 100%;
    background: #f2f2f2;
    margin-top: 15px;
    border: 1px solid rgba(72, 201, 176, 1);
    border-bottom: none;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
}

.c-c-header{
    background: #17202a;
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    justify-content: space-evenly;
}

.c-c-header div, .c-content div{
    width: 1px;
    height: 100%;
    background: rgba(72, 201, 176, 1);
}

.c-c-header span{
    color: #f2f2f2;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.c-content{
    width: 100%;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-evenly;
    border-bottom: 1px solid rgba(72, 201, 176, 1);
}
.c-content span{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 400px) {
    .cours-title{
      font-size: small;
    }
  }