.exchange-modal-container{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.exchange-box{
    width: max-content;
    min-width: 300px;
    height: max-content;
    background: white;
    position: relative;
    min-height: 100px;
    min-width: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 35px;
    padding-bottom: 20px;
    padding-right: 15px;
    padding-left: 15px;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .1);
}
.cancel-button{
    top: 10px;
    right: 10px;
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    color: #17202a;
}
.exchange-btn{
    margin-top: 10px;
    margin-bottom: 10px;
    height: 45px;
    width: 200px;
    border-radius: 5px;
    background: rgba(72, 201, 176, 1);
    color: #f2f2f2;
    border: none;
    cursor: pointer;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.exchange-btn:hover{
    background: rgb(55, 163, 142);
}
.exchange-btn span{
    margin-left: 5px;
}
.exchange-info-container{
    display: flex;
    flex-direction: column;
    position: relative;
    color: #17202a;
    width: 300px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.ex-input{
    height: 40px;
    border: 1px solid #f0f0f0;
    background: #f5f5f5;
    outline: none;
    margin-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: 0 0 1px 1px rgba(72, 201, 176, .1);
}
.exchange-info-container label{
    margin-top: 10px;
}

.step-btn-container{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.step-btn-container button{
    transition: .3s;
    height: 45px;
    width: 120px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
.anable-button{
    height: 45px;
    width: 120px;
    border-radius: 5px;
    cursor:not-allowed;
    color: #fff;
    background: rgb(55, 163, 142);
    opacity: .2;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(72, 201, 176, 1);
}
.prev{
    border: 1px solid rgba(72, 201, 176, 1);
}
.prev:hover{
    background: #DDD;
}
.next{
    border: 1px solid rgba(72, 201, 176, 1);
    background: rgba(72, 201, 176, 1);
    color: #fff;
}
.next:hover{
    background: rgb(62, 165, 144);
}


.error-amount{
    width: 100%;
    margin-top: 10px;
    color: red;
}

.checkout{
    display: flex;
    align-items: center;
}
.checkout div{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    background: green;
    display: flex;
    align-items: center;
    justify-content: center;
}
.checkout strong{
    margin-left: 10px;
}
.checkout-p{
    margin: 0;
    margin-top: 15px;
}
.checkout-p span{
    font-style: italic;
}

.checkout-p button{
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    background: #DDD;
}

.finish-container{
    width: 100%;
}
.small{
    font-size: small;
}
.btn-copy{
    margin-left: 10px;
    cursor: pointer;
    border: none;
    background: #DDD;
    height: 35px;
    width: 35px;
    border-radius: 5px;
}

.done-btn{
    width: 100%;
    background: rgb(55, 163, 142);
    height: 40px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    transition: .3s;
    font-weight: bold;
}
.done-btn:hover{
    background: rgb(50, 128, 112);
}
.warning{
    color: rgb(241, 167, 9);
}