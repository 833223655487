.notif-container{
    width: 96%;
    margin-left: 2%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.no-notif{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notif-modal{
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;
}

.n-m-btn-container{
    width: 80%;
    display: flex;
    justify-content: end;
}

.n-m-btn-container button{
    height: 40px;
    width: 40px;
    border-radius: 3px;
    border: none;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    cursor: pointer;
    transition: .3s;
}
.n-m-btn-container button:hover{
    background: #f0f0f0;
}

.n-modal-content{
    width: 80%;
    display: flex;
    flex-direction: column;
    background: white;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
}
.n-modal-content p{
    margin-left: 10px;
    margin-right: 10px;
    line-height: 25px;
}

.n-modal-content div{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.n-modal-content div h2{
    text-align: center;
}

.n-modal-content div img{
    max-width: 400px;
    max-height: 400px;
    width: 80%;
}