.home{
    max-width: 96%;
    margin-left: 2%;
    flex-grow: 1;
}

.money-list-container{
    margin-top: 15px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
    padding-bottom: 15px;
    position: relative;
}
.money-list-container-unavailable{
    margin-top: 15px;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding-bottom: 15px;
    position: relative;
}

.title{
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.title span{
    margin-left: 7px;
}

.product-container{
    height: 150px;
    width: 100%;
    background:  #17202a;
    color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
}