.notif-box{
    height: 60px;
    width: 100%;
    margin-top: 7px;
    margin-bottom: 7px;
    border-radius: 3px;
    background: #f2f2f2;
    position: relative;
    display: flex;
    align-items: center;
}
.bold{
    font-weight: bolder;
}

.notif-box span{
    margin-left: 15px;
}

.notif-box button{
    position: absolute;
    right: 15px;
    height: 40px;
    background: rgba(72, 201, 176, 1);
    color: white;
    border: none;
    cursor: pointer;
    transition: .3s;
    border-radius: 3px;
}
.notif-box button:hover{
    background: rgb(64, 175, 153);
}