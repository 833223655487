.unavailable{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .7);
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f2f2f2;
}