.message-container{
    position: fixed;
    top: -300px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
.msg-box{
    background-color: #17202a;
    padding: 15px;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    border-radius: 4px;
    max-width: 300px;
    line-height: 22px;
}
.msg-info{
    color: white;
}
.msg-success{
    color: rgb(13, 153, 13);
}
.msg-warning{
    color: rgb(248, 184, 9);
}
.msg-critical{
    color: red;
}