.balance{
    margin-top: 15px;
}
.balance-title{
    color: #17202a;
    display: flex;
    align-items: center;
}
.balance-title span{
    margin-left: 7px;
    font-size: 20px;
}
.balance-container{
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
}
.balance-box{
    min-height: 50px;
    background: #17202a;
    border-radius: 7px;
    box-shadow: 0 0 2px 2px rgba(72, 201, 176, .3);
    width: 30%;
    display: flex;
    justify-content: space-between;
}

.balance-id{
    display: flex;
    align-items: center;
    padding: 10px;
}
.balance-id img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
}
.balance-id strong{
    color: #f2f2f2;
    margin-left: 10px;
}
.balance-content{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f0f0f0;
    margin-right: 10px;
    font-family: 'Segoe UI';
    font-weight: lighter;
    font-size: large;
}

@media screen and (max-width: 850px) {
    .balance-container{
        flex-direction: column;
        justify-content: center;
        margin-top: 0px;
    }
    .balance-box{
        width: 100%;
        margin-top: 15px;
    }
}